export class AppDataHelper {
	static get appName(): string {
		return 'Figurenotes Composer';
	}
	static get appVersion(): string {
		return '1.0.0';
	}
	static get scoreFileExt(): string {
		return 'fnc';
	}
}
